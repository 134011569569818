import axios from "axios";
import { SendErrorMessage } from "./messages";
//----------------------
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API}`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    SendErrorMessage(error.response.data.error.message)
    if (error.response.data.error.message === "Error: Sesión invalida.") {
      // enqueueSnackbar("Tu sesión ha caducado. Serás redirigido al Login.", {
      //   variant: "error",
      // });
      localStorage.removeItem("accessToken");
      window.localStorage.clear();

      // setTimeout(() => {
      //   localStorage.removeItem("accessToken");
      //   window.localStorage.clear();
      // }, 3000);

    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     const messageError = error.response.data.error.message;
//     console.log(messageError)
//     if (messageError === "Error: Sesión invalida.") {
//       enqueueSnackbar("Tu sesión ha caducado. Serás redirigido al Login.", {
//         variant: "error",
//       });

//       setTimeout(() => {
//         localStorage.removeItem('accessToken');
//         window.localStorage.clear();
//         // window.location.reload();
//       }, 3000);
//     } else {
//       // ToastNotification(messageError, "error");
//     }

//     return Promise.reject(error);
//   }
// );
