import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@mui/material/styles";
import { Box, Link, Drawer, Typography } from "@mui/material";
// hooks
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
// ----------------------------------------------------------------------
import SettingsIcon from "@mui/icons-material/Settings";
const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[300],
}));

// const DocStyle = styled("div")(({ theme }) => ({
//   padding: theme.spacing(2.5),
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor:
//     theme.palette.mode === "light"
//       ? alpha(theme.palette.primary.main, 0.08)
//       : theme.palette.primary.main,
// }));

// ----------------------------------------------------------------------

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: DashboardSidebarProps) {
  const { pathname } = useLocation();
  // const { user} = useAuth()

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 2, mx: 2.5, flexWrap: "wrap" }}>
        <Link
         
          underline="none"
          component={RouterLink}
          to={PATH_DASHBOARD.user.account}
        >
          <AccountStyle
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <MyAvatar />
            <Box sx={{ ml: 1 ,flexWrap:'wrap', width:'100%'}}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user?.user.email.split('@')[0]}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', textAlign:'center' }}>
                {user?.user.email.length > 20 ? user?.user.email.slice(0,20) + '...' : user?.user.email}
              </Typography>
            </Box> */}
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", textAlign: "center" }}
            >
              Configuración de usuario/cuenta
            </Typography>
            <SettingsIcon sx={{color:'text.secondary'}}/>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection  navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: "background.default" },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
