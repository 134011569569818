// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import SvgIconStyle from "../../components/SvgIconStyle";
// import path from "path";
// import { title } from "process";
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const rolUser = localStorage.getItem("prefixType");
const status = localStorage.getItem("clStatus");

let statusClient = false;

if (status === "false") {
  statusClient = false;
} else {
  statusClient = Boolean(status);
}

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  kanban: getIcon("ic_kanban"),
  home: getIcon("ic_home"),
  question: getIcon("ic_question"),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Inicio",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.home,
      },
      ...(rolUser === "OWN"
        ? [
            {
              title: "Eventos",
              path: PATH_DASHBOARD.clients.root,
              icon: ICONS.kanban,
              children: [
                { title: "mis eventos", path: PATH_DASHBOARD.clients.root },
              ],
            },
          ]
        : [
            {
              title: "Empresa",
              path: PATH_DASHBOARD.clients.root,
              icon: ICONS.ecommerce,
              children: [
                {
                  title: "gestionar empresas",
                  path: PATH_DASHBOARD.clients.root,
                },
              ],
            },
            {
              title: "Configuración",
              path: PATH_DASHBOARD.clients.root,
              icon: <PermDataSettingIcon/>,
              children: [
                {
                  title: "Preguntas",
                  path: PATH_DASHBOARD.clients.detail.questions,
                },
              ],
            },
          ]),
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------

  rolUser === "OWN" && statusClient
    ? {
        subheader: "Gestión",
        items: [
          // MANAGEMENT : USER
          {
            title: "Cuenta",
            path: PATH_DASHBOARD.user.root,
            icon: ICONS.user,
            children: [
              { title: "Mi cuenta", path: PATH_DASHBOARD.user.account },
            ],
          },
        ],
      }
    : rolUser === "ADM" && !statusClient
    ? {
        subheader: "",
        items: [
          // {
          //   title: "Cuenta",
          //   icon: ICONS.user,
          //   path: PATH_DASHBOARD.user.root,
          //   children: [{ title: "Mi cuenta", path: PATH_DASHBOARD.user.admin }],
          // },
        ],
      }
    : {
        subheader: "",
        items: [],
      },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [

  //   ]
  // }
];

export default sidebarConfig;
