import { createSlice } from "@reduxjs/toolkit";
// import { ExhibitorFormCreateType } from "components/_client/List/Exhibitor/helper";
import { pairedExhibitorToStand, updateExhibitorToStand } from "services";

const initialState = {
  isEmpty: true,
  currentStand: [],
  stands: [],
  loading: false,
};

export const eventSlice = createSlice({
  name: "stand",
  initialState,
  reducers: {
    setCurrentStand: (state, action) => {
      state.isEmpty = action.payload.isEmpty;
      state.currentStand = action.payload.currentStand;
    },
    clearStand: () => {
      return initialState;
    },
    setStands: (state, action) => {
      state.stands = action.payload.stands;
    },
    setStandLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setCurrentStand, clearStand, setStands, setStandLoading } =
  eventSlice.actions;
export default eventSlice.reducer;

export const createExhibitorForStand =
  (exhibitor: any, onError: () => void, onSuccess : () => void) =>
  async (dispatch: any) => {
    try {
      dispatch(setStandLoading(true));
      const { status } = await pairedExhibitorToStand(exhibitor);
      if (status === 200) {
        onSuccess();
      }
    } catch (error) {
      onError();
    } finally {
      dispatch(setStandLoading(false));
    }
  };

  export const updateExhibitorForStand =
  (exhibitor: any, userId:number, onError: () => void, onSuccess : () => void) =>
  async (dispatch: any) => {
    try {
      dispatch(setStandLoading(true));
      const { status } = await updateExhibitorToStand(exhibitor, userId);
      if (status === 204) {
        onSuccess();
      }
    } catch (error) {
      onError();
    } finally {
      dispatch(setStandLoading(false));
    }
  };
