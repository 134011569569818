import axiosInstance from "utils/axios";

export const getAllSpeakers = (eventId: number) => {
  return axiosInstance.get(
    `/speakers?filter=${encodeURIComponent(
      JSON.stringify({ where: { eventId: eventId } })
    )}`
  );
};

export const getAllSpeakersFilter = (eventId: number) => {
  return axiosInstance.get(
    `/speakers?filter=${encodeURIComponent(
      JSON.stringify({ where: { eventId: eventId,status:1 } })
    )}`
  );
};


export const createSpeaker = (values: any) => {
  return axiosInstance.post("/speakers", values);
};

export const updateSpeaker = (values: any, id: number) => {
  return axiosInstance.patch(`speakers/${id}`, values);
};
