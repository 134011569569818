//@mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

// const GlobalStyles = withStyles((theme) => ({

//   '@global': {
//     '*': {
//       margin: 0,
//       padding: 0,
//       boxSizing: 'border-box'
//     },
//     html: {
//       width: '100%',
//       height: '100%',
//       '-ms-text-size-adjust': '100%',
//       '-webkit-overflow-scrolling': 'touch'
//     },
//     body: {
//       width: '100%',
//       height: '100%'
//     },
//     '#root': {
//       width: '100%',
//       height: '100%'
//     },
//     input: {
//       '&[type=number]': {
//         MozAppearance: 'textfield',
//         '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
//         '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
//       }
//     },
//     textarea: {
//       '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
//       '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
//       '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
//       '&::placeholder': { color: theme.palette.text.disabled }
//     },
//     a: { color: theme.palette.primary.main },
//     img: { display: 'block', maxWidth: '100%' },

//     // Lazy Load Img
//     '.blur-up': {
//       WebkitFilter: 'blur(5px)',
//       filter: 'blur(5px)',
//       transition: 'filter 400ms, -webkit-filter 400ms'
//     },
//     '.blur-up.lazyloaded ': {
//       WebkitFilter: 'blur(0)',
//       filter: 'blur(0)'
//     }
//   }
// }))(() => null);

// export default GlobalStyles;

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={(theme) => ({
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        html: {
          width: '100%',
          height: '100%',
          msTextSizeAdjust: '100%',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          height: '100%'
        },
        '#root': {
          width: '100%',
          height: '100%'
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
            '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
          }
        },
        textarea: {
          '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
          '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
          '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
          '&::placeholder': { color: theme.palette.text.disabled }
        },
        a: { color: theme.palette.primary.main },
        img: { display: 'block', maxWidth: '100%' },
    
        // Lazy Load Img
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms'
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)'
        },
        '#nprogress': {
          pointerEvents: 'none',
          '& .bar': {
            top: 0,
            left: 0,
            height: 2,
            width: '100%',
            position: 'fixed',
            // zIndex: theme.zIndex.snackbar,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 0 2px ${theme.palette.primary.main}`
          },
          '& .peg': {
            right: 0,
            opacity: 1,
            width: 100,
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
            boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
          }
        }
      })}
    />
  );

  return inputGlobalStyles;
}