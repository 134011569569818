import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";

export const SendErrorMessage = (value: string) => {

  const regex = /Entity not found: User with id "\d+"/;

  if(value !== "Error: Sesión invalida." && !regex.test(value) ) {
    enqueueSnackbar(value, {
      variant: "error",
    });
  }
};

export const SendSuccessMessage = (msg: string) => {
  enqueueSnackbar(msg, {
    variant: "success",
    action: (key) => (
      <IconButton onClick={() => closeSnackbar(key)}>
        <Icon icon="ant-design:close-outlined" />
      </IconButton>
    ),
  });
};