import axiosInstance from "utils/axios";

export const getPublicitiesEventById = (eventId: number) => {
  return axiosInstance.get(
    `/publicities?${encodeURI(
      `filter={"where":{"eventId":${eventId}},"fields":{"id":true,"resourceId":true}}`
    )}`
  );
};

export const uploadPublicityByEventId = (eventId: number, image: FormData) => {
  return axiosInstance.post(`/publicities/uploadPublicity/${eventId}`, image, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updatePublicityById = (publicityId:number,eventId: number, image: FormData) => {
    return axiosInstance.post(`/publicities/updatePublicity/${publicityId}/${eventId}`, image, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

export const deletePublicityByResourceId = (resourceId: number) => {
  return axiosInstance.delete(`/publicities/${resourceId}`);
};

export const getPublicityByResourceId = (resourceId: number) => {
  return axiosInstance.get(`/publicities/downloadPublicity/${resourceId}`, {
    responseType: "blob",
  });
};
