import { IconButton, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BorderColorIcon from "@mui/icons-material/BorderColor";

interface ProductsActionCellProps {
  handleEditClick: any;
  params: any;
  changeTagStatus: any;
}

function ProductActionCell({
  changeTagStatus,
  handleEditClick,
  params,
}: ProductsActionCellProps) {
  return (
<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 10 }}>
      <Tooltip title="Editar" arrow>
        <IconButton
          aria-label="edit"
          onClick={() => handleEditClick(params.row)}
        >
          <BorderColorIcon sx={{ color: "black" }} />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={params.row.status === 1 ? "Deshabilitar" : "Habilitar"}
        arrow
      >
        <IconButton
          aria-label="delete"
          onClick={() => changeTagStatus(params.row)}
        >
          {params.row.status === 1 ? (
            <CancelIcon sx={{ color: "black" }} />
          ) : (
            <CheckCircleIcon sx={{ color: "black" }} />
          )}
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default ProductActionCell;
