import { LoadingButton } from '@mui/lab';
import { Dispatch, SetStateAction } from 'react'

interface IProps {
    stateModal?: Dispatch<SetStateAction<boolean>>;
    title: string;
    sizeWidth: number;
    typeBtn: string;
    isSumiting?: boolean;
    onclick?: any;
    mb?: number;
    mt?: number;
}

export const ButtonSubmit = ( { stateModal, title, sizeWidth, typeBtn, isSumiting, onclick, mb=4, mt=1 } : IProps ) => {
  return (
    <LoadingButton
        type={typeBtn !== '' ? "submit" : "button"}
        onClick={ stateModal ?  () => stateModal(true) : onclick }
        variant="contained"
        loading={isSumiting}
        sx={{
          fontSize: 20,
          width:`${sizeWidth}%`,
          height: 50,
          backgroundColor: "#343A40",
          mb,
          mt,
          color: "#fff",
          "&:hover": {
            backgroundColor: "#000512",
            color: "#E0912F",
          },
        }}
      >
        {title}
      </LoadingButton>
  )
}
