import { ReactNode } from "react";
import { Box } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { Icon } from '@iconify/react';
import { makeStyles } from "@mui/styles";
import { ColorSchema } from "../@types/theme";
import { alpha } from "@mui/material/styles";

const useStyles = makeStyles((theme:any) => {
  const isLight = theme.palette.mode === 'light';

  const createStyle = {
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: `${theme.palette.background.paper} !important`
  };

  return {
    containerRoot: {
      pointerEvents: 'unset',
      '& .MuiCollapse-wrapperInner': {
        width: '100%'
      }
    },
    contentRoot: {
      width: '100%',
      padding: theme.spacing(1.5),
      margin: theme.spacing(0.25, 0),
      boxShadow: theme.customShadows.z8,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.grey[isLight ? 0 : 800],
      backgroundColor: theme.palette.grey[isLight ? 900 : 0]
    },
    message: {
      padding: 0,
      fontWeight: theme.typography.fontWeightMedium
    },
    action: {
      marginRight: -4,
      '& svg': {
        width: 20,
        height: 20,
        opacity: 0.48,
        '&:hover': { opacity: 1 }
      }
    },
    variantInfo: { ...createStyle },
    variantSuccess: { ...createStyle },
    variantWarning: { ...createStyle },
    variantError: { ...createStyle }
  };
});

type SnackbarIconProps = {
  icon: string;
  color: ColorSchema;
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16)
      }}
    >
      <Icon icon={icon} width={24} height={24} />
    </Box>
  );
}

type NotistackProviderProps = {
  children: ReactNode;
};

function NotistackProvider({ children }: NotistackProviderProps) {
  const classes = useStyles();

  return (
    <SnackbarProvider
      dense
      maxSnack={5}
      // preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      iconVariant={{
        success: <SnackbarIcon icon="eva:checkmark-circle-2-fill" color="success" />,
        error: <SnackbarIcon icon="eva:info-fill" color="error" />,
        warning: <SnackbarIcon icon="eva:alert-triangle-fill" color="warning" />,
        info: <SnackbarIcon icon="eva:alert-circle-fill" color="info" />
      }}
      classes={classes}
    >
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;