import { ExhibitorFormCreateType } from "components/_client/List/Exhibitor/helper";
import axiosInstance from "utils/axios";

export const createStandById = (stand: Object) => {
  return axiosInstance.post("/stands", stand);
};

// export const createStandById = (stand:Object,id:number| null) => {
//     return axiosInstance.post(`exhibitors/${id}/stands`, stand);
// }

export const updateStandById = (stand: Object, id: number) => {
  return axiosInstance.patch(`/stands/${id}`, stand);
};
//2

export const getCurrentStandById = (id: number | null | undefined) => {
  return axiosInstance.get(
    `/stands/${id}?${encodeURI('filter={"include":["resource","exhibitor","tags"]}')}`
  );
};

export const getAllStands = (eventId: number) => {
  return axiosInstance.get(
    `/stands?${encodeURI(
      `filter={"where":{"eventId":${eventId}}, "include":["exhibitor","tags"]}`
    )}`
  );
};

export const uploadStandBanner = (standId: number, file: File) => {
  const formData = new FormData();  
  formData.append('archivo', file);
  return axiosInstance.post(`stands/uploadBanner/${standId}`, formData);
};

export const updateStandBanner = (standId: number, file: File) => {
  const formData = new FormData();  
  formData.append('archivo', file);
  return axiosInstance.post(`stands/updateBanner/${standId}`, formData);
};

// export const updateStandBanner = (
//   standId: number | null | undefined,
//   banner: FormData | undefined
// ) => {
//   return axiosInstance.post(`stands/updateBanner/${standId}`,banner, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   });
// };

export const Count = () => {
  return axiosInstance.get(`/stands/count`);
};

export const pairedExhibitorToStand = (form:ExhibitorFormCreateType) => {
  return axiosInstance.post(`/users/users-exhibitor-link`, form);
}

export const updateExhibitorToStand = (data: any, userId: number) => {
  return axiosInstance.patch(`/users/user-exhibitor-update/${userId}`, data)
}
