import { DataGrid, GridToolbar, GridCellParams,esES } from "@mui/x-data-grid";
import { RootState } from "redux/store";
import { createStandProdcut,getAllProductsByStand,updateStandProduct } from "services";
import { Button,Stack,Tooltip,Typography,Box } from "@mui/material";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { CustomPagination } from "components/pagination/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DialogProduct from "../../../components/_client/stand/products/DialogProduct";
import ProductActionCell from "components/_client/stand/products/ProductActionCell";

export default function StandProduct() {
  const navigate = useNavigate();
  const { enterpriseId, eventId, standId } = useParams();
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const { currentStand } = useSelector((state: RootState) => state.stand);
  const [update, isUpdate] = useState(false);
  const [loading, isLoading] = useState(false);

  let initialValue = {
    id: 0,
    name: "",
    cost: 0,
    standId: standId,
  };

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    getAllProductsByStandId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllProductsByStandId = async () => {
    const { data } = await getAllProductsByStand(Number(standId));
    setProducts(data);
  };

  const handleClose = () => {setOpen(false)};

  const newStandProduct = () => {
    isUpdate(false);
    setValue(initialValue);
    setOpen(true);
  };

  const saveProduct = async (e: any) => {
    e.preventDefault();
    isLoading(true);
    let params = {
      name: value.name,
      cost: value.cost,
      standId: Number(standId),
    };
    if (!update) {
      await createStandProdcut(params).then((response) => {
        let label = update ? "actualizado" : "guardado";
        if (response.status === 200) {
          setOpen(false);
          enqueueSnackbar(`Tag ${label} correctamente`, {
            variant: "success",
          });
          getAllProductsByStandId();
          isLoading(false);
        } else {
          enqueueSnackbar(response.status, {
            variant: "error",
          });
          isLoading(false);
        }
      });
    } else {
      // delete params.eventId;
      await updateStandProduct(params, value.id).then((response: any) => {
        let label = update ? "actualizado" : "guardado";
        if (response.status === 204) {
          setOpen(false);
          enqueueSnackbar(`Tag ${label} correctamente`, {
            variant: "success",
          });
          getAllProductsByStandId();
          isLoading(false);
        } else {
          enqueueSnackbar(response.error, {
            variant: "error",
          });
          isLoading(false);
        }
      });
    }
  };

  const handleEditClick = (row: any) => {
    setValue(row);
    isUpdate(true);
    setOpen(true);
  };

  const goToStandEvent = () => {
    navigate(
      `/dashboard/client/enterprise/${Number(
        enterpriseId
      )}/event/${Number(eventId)}/stand/${Number(standId)}`,
      { replace: true }
    )
  }

  const changeTagStatus = async (row: any) => {
    let params = {
      status: row.status === 1 ? 0 : 1,
    };
    await updateStandProduct(params, row.id).then((response: any) => {
      if (response.status === 204) {
        setOpen(false);
        enqueueSnackbar(`Tag actualizado correctamente`, {
          variant: "success",
        });
        getAllProductsByStandId();
        isLoading(false);
      } else {
        enqueueSnackbar(response.error, {
          variant: "error",
        });
        isLoading(false);
      }
    });
  };

  return (
    <Box
      sx={{
        height: "75vh",
        width: "100%",
        gap: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Stack
        direction="row"
        width="70%"
        spacing={5}
        justifyContent="space-between"
      >
        <Tooltip arrow title="Regresar">
          <Icon
            style={{ cursor: "pointer" }}
            width={40}
            height={40}
            icon={"material-symbols:arrow-circle-left"}
            onClick={() => goToStandEvent()}
          />
        </Tooltip>
        <Typography variant="h4">Productos ({currentStand.name})</Typography>
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            newStandProduct();
          }}
        >
          Crear producto
        </Button>
      </Stack>

      <DataGrid
        sx={{
          // justifyContent: "center",
          // display: "flex",
          // alignSelf: "center",
          // alignItems: "center",
          width: "100%",
        }}
        localeText={{
          ...esES.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: "Sin productos.",
        }}
        rows={products}
        slots={{
          toolbar: GridToolbar,
          pagination: CustomPagination,
        }}
        getCellClassName={(params) => {
          if (params.field === "status") {
            return params.value === "Activa"
              ? "active-cell"
              : "inactive-cell";
          }
          return "";
        }}
        columns={[
          { field: "id", headerName: "ID", width: 100 },
          { field: "name", headerName: "Producto", width: 200 },
          { field: "cost", headerName: "Valor(puntos)", width: 200 },
          {
            field: "status",
            headerName: "Estado",
            width: 150,
            valueGetter: (params) =>
              params.row.status === 1 ? `Activa` : `Inactiva`,
          },
          {
            field: "",
            headerName: "Acciones",
            width: 150,
            // align: "center",
            renderCell: (params: GridCellParams) => {
              return (
                <ProductActionCell
                  changeTagStatus={changeTagStatus}
                  handleEditClick={handleEditClick}
                  params={params}
                />
              );
            },
          },
        ]}
        pagination
      />
      <DialogProduct
        handleClose={handleClose}
        loading={loading}
        open={open}
        saveProduct={saveProduct}
        setValue={setValue}
        update={update}
        value={value}
      />
    </Box>
  );
}
