import { NotificationType } from "@types";
import axiosInstance from "utils/axios";

export const uploadCredentials = (enterpriseId: number, form: FormData) => {
  return axiosInstance.post(
    `/notification-credentials/import/${enterpriseId}`,
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getNotificationsByEvent = (eventId: number) => {
  return axiosInstance.get(
    `/notifications?filter=${encodeURIComponent(
      JSON.stringify({ where: { eventId: eventId, notificationTypeId: 1 } })
    )}`
  );
};

export const postNotifications = (value:NotificationType) =>{
  return axiosInstance.post('/notifications/send-notifications', value)

}