import './App.css';
import ThemeConfig from './theme';
import NotistackProvider from './components/NotistackProvider';
import ScrollToTop from './components/ScrollToTop';
import useAuth from './hooks/useAuth';
import Router from './routes';
import LoadingScreen from './components/LoadingScreen';
import { APIProvider } from '@vis.gl/react-google-maps';
const API_KEY = (globalThis as any)?.GOOGLE_MAPS_API_KEY ?? `${process.env.REACT_APP_GOOGLE_MAP}`;

function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <APIProvider apiKey={API_KEY}>
        <NotistackProvider>
        <ScrollToTop />
          {isInitialized ? <Router /> : <LoadingScreen />}
        </NotistackProvider> 
      </APIProvider>
    </ThemeConfig>
  );
}

export default App;
