import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
// material
import {
  Link,
  Stack,
  Alert,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
// routes
import { PATH_AUTH } from "../../../routes/paths";
// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
//
import { ButtonSubmit } from "pages/Landing/UI/ButtonSubmit";
import { enqueueSnackbar } from "notistack";

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};
export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { isInitialized } = useAuth();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("El correo electrónico debe ser una dirección de correo electrónico válida.")
      .required("El correo electronico es requerido"),
    password: Yup.string().required("Se requiere contraseña"),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        if (localStorage.getItem("accessToken")) {
          return enqueueSnackbar(
            "¡Hola! Parece que ya tienes una sesión activa en este navegador.",
            {
              variant: "error",
            }
          );
        }

        await login(values.email, values.password);

        if (isMountedRef.current) {
          setSubmitting(false);
        }
        navigate("/dashboard/app", { replace: true });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } catch (error: any) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    //verify if exists accessToken redirect to /dashboard
    if (localStorage.getItem("accessToken")) {
      navigate("/dashboard/app", { replace: true });
    }
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}

          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Contraseña"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="Recordarme"
          /> */}

          <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.resetPassword}
          >
            ¿Has olvidado tu contraseña?
          </Link>
        </Stack>

        <ButtonSubmit
          typeBtn="submit"
          isSumiting={isSubmitting}
          sizeWidth={100}
          title="Acceder"
        />
      </Form>
    </FormikProvider>
  );
}
