import axiosInstance from "utils/axios";

export const registerUser = (values: Object) => {
  return axiosInstance.post("/users", values);
};

export const sendEmail = (email: string, flag: boolean) => {
  return axiosInstance.post(`/users/sendEmailRecovery`, { email });
};

export const loginPost = (email: string, passwordUser: string) => {
  return axiosInstance.post("/users/loginAdmin", {
    email,
    passwordUser
  });
};

export const resetPassword = (credentials: Object) => {
  return axiosInstance.patch("/users/resetPassword", credentials);
};

export const getUserById = (id:number, filter?:any) => {
  return axiosInstance.get(`/users/${id}?filter=${encodeURI(JSON.stringify(filter??{}))}`);
}
