import axiosInstance from "utils/axios";

export const getAllEvents = (eId: number) => {
  return axiosInstance.get(
    `/events?${encodeURI(
      `filter={"where":{"enterpriseId":${eId}},"include":["address","stands"]}`
    )}`
  );
};

export const getEventById = (id: number, filter: any = {}) => {
  return axiosInstance.get(
    `/events/${id}?filter=${encodeURI(JSON.stringify(filter))}`
  );
};

export const getEventAssistants = (id: number, flag: boolean) => {
  return axiosInstance.get(`assistants/get-by-event/${id}/${flag}`);
};

export const createEvent = (event: Object) => {
  //const newEvent = { ...event, coordLat: "", coordLong: "" };
  return axiosInstance.post("/events", event);
};

export const updateEvent = (event: Object, id: number) => {
  return axiosInstance.patch(`/events/${id}`, event);
};

export const getDataFromEventById = (id: number) => {
  return axiosInstance.get(
    `/events/${id}?${encodeURI(
      'filter={ "include": [ { "relation": "address", "scope": { "include": [ { "relation": "town", "scope": { "fields": ["name"] } }, { "relation": "country", "scope": { "fields": ["name"] } }, { "relation": "province", "scope": { "fields": ["name"] } } ] } }, { "relation": "eventSchedules" },{ "relation": "page" }  ] }'
    )}`
  );
};

export const disableEvent = (eventId: number, status: number) => {
  return axiosInstance.patch(`/events/changeStatus/${eventId}`, {
    status: status,
  });
};

export const deleteEvent = (id: number) => {
  return axiosInstance.delete(`/events/${id}`);
};

export const getCountries = () => {
  return axiosInstance.get(`/countries`);
};

export const getProvinceByCountryId = (countryId?: number) => {
  return axiosInstance.get(
    `/provinces?${encodeURI(`filter={"where":{"countryId":${countryId}}}`)}`
  );
};

export const getTownByProvinceId = (provinceId?: number) => {
  return axiosInstance.get(
    `/towns?${encodeURI(`filter={"where":{"provinceId":${provinceId}}}`)}`
  );
};

export const getTagsByEvent = (eventId: any) => {
  return axiosInstance.get(`/events/${eventId}/tags`);
};

export const createTag = (tag: Object) => {
  return axiosInstance.post("/tags", tag);
};

export const updateTag = (tag: Object, id: number) => {
  return axiosInstance.patch(`/tags/${id}`, tag);
};

export const getAllProductsByStand = (standId: number) => {
  return axiosInstance.get(
    `/stand-products?${encodeURI(`filter={"where":{"standId":${standId}}}`)}`
  );
};

export const createStandProdcut = (tag: Object) => {
  return axiosInstance.post("/stand-products", tag);
};

export const updateStandProduct = (tag: Object, id: number) => {
  return axiosInstance.patch(`/stand-products/${id}`, tag);
};

export const uploadMapEvent = (eventId: number, map: FormData) => {
  return axiosInstance.post(`events/uploadMap/` + eventId, map, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateMapEvent = (eventId: number, map: FormData) => {
  return axiosInstance.post(`events/updateMap/` + eventId, map, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadImageLanding = (
  eventId: number,
  form:FormData
) => {
  return axiosInstance.post(`events/uploadImagesLanding/${eventId}`, form);
};

export const updateImageLanding = (
  eventId: number,
  page_id: number | null,
  background: File,
  bannerDown: File
) => {
  let formdata = new FormData();
  formdata.append("background", background);
  formdata.append("bannerDown", bannerDown);

  return axiosInstance.patch(
    `events/updateImagesLanding/${eventId}/${page_id}`,
    formdata
  );
};

export const eventForClothing = (prefix: string | undefined) => {
  return axiosInstance.post(
    `events/find-by-prefix`,
    { prefix: prefix }
  );
};

export const getEventClothing = (id: number) => {
  return axiosInstance.get(`/publicities/downloadPublicity/${id}`, {
    responseType: "blob",
  });
};

export const getEventByIdUnfiltered = (id: number, filter: any = {}) => {
  return axiosInstance.get(`/events/${id}`);
};

export const UploadNewsEventMultiple = (eventId:number,file:FormData) =>{
  return axiosInstance.post(`/events/${eventId}/event-news`,file)
}

export const getEventNews = (eventId:number) =>{
  return axiosInstance.get(`/events/${eventId}/event-news`)
}

export const deleteNews = (eventId:number, idResource:number)=>{
  return axiosInstance.delete(`/events/${eventId}/event-news/${idResource}`)
}

export const patchPageEvent = (eventId:number, form:any)=>{
  return axiosInstance.patch(`/events/${eventId}/page`, form);
}

export const getPageEvent = (eventId:number)=>{
  return axiosInstance.get(`/events/${eventId}/page`);
}

export const uploadAdditionalFile = (eventId:number, form:any) => {
  return axiosInstance.post(`/events/${eventId}/additional/resource`, form);
}

export const deleteAdditionalFile = (pageId:number, resourceId:any) => {
  return axiosInstance.delete(`/page/additional/resource/${resourceId}`);
}