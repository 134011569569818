import { Suspense, lazy } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import AuthGuard from "../guards/AuthGuard";

import LoadingScreen from "../components/LoadingScreen";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import StandProduct from "pages/dashboard/stands/StandProducts";

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const userEnterprise = localStorage.getItem("idntyEntp");
  let userRole = window.localStorage.getItem("prefixType");
  const status = window.localStorage.getItem("clStatus");
  let statusClient = false;

  if (status === "false") {
    statusClient = false;
  } else {
    statusClient = Boolean(status);
  }

  return useRoutes([
    // {
    //   path: 'auth',
    //   children: [
    //     { path: 'login', element: <Login /> },
    //     { path: 'register', element: <Register /> },
    //   ]
    // },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to="/dashboard/app" /> },
        { path: "app", element: <GeneralApp /> },
        {
          path: "user",
          children: [
            { index: true, element: <Navigate to="/dashboard/user/profile" /> },
            { path: "profile", element: <UserProfile /> },
            { path: "account", element: <UserAccount /> },
            { path: "admin", element: <AdminAccount /> },
          ],
        },
        {
          path: "client",
          children:
            userRole === "ADM"
              ? [
                  {
                    index: true,
                    element: <Navigate to="/dashboard/client/enterprise" />,
                  },

                  { path: "enterprise", element: <PanelClient /> },
                  { path: "enterprise/:id", element: <PanelEvent /> },
                  { path: "questions", element:<PanelQuestions/>},
                ]
              : userRole === "OWN" && statusClient
              ? [
                  {
                    index: true,
                    element: (
                      <Navigate
                        to={"/dashboard/client/enterprise/" + userEnterprise}
                      />
                    ),
                  },
                  { path: "enterprise/:id", element: <PanelEvent /> },
                  {
                    path: "enterprise/:enterpriseId/event/:eventId",
                    children: [
                      {
                        index: true,
                        element: <PanelEventByStandOrConference />,
                      },
                      {
                        path: "stand/:standId",
                        element: <PanelEventByStandOrConference />,
                      },
                      {
                        path: "stand/:standId/products",
                        element: <StandProduct />,
                      },
                      {
                        path: "conference/:conferenceId",
                        element: <PanelEventByStandOrConference />,
                      },
                      {
                        path: "conference/:conferenceId/conferenceEvent/:conferenceEventId/list",
                        element: <PanelEventByStandOrConference />,
                      },
                      {
                        path: "conference/:conferenceId/conferenceEvent/:conferenceEventId",
                        element: <Update />,
                      },
                    ],
                  },
                ]
              : userRole === "OWN" && !statusClient
              ? [
                  {
                    index: true,
                    element: <Navigate to="/dashboard/client/clientInactive" />,
                  },
                  {
                    path: "clientInactive",
                    element: <ClientInactive />,
                  },
                ]
              : [],
        },
      ],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      //path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <LandingStatic /> },
        { path: "/:prefix/inicio", element: <Landing /> },
        { path: ":prefix/register", element: <Register /> },
        { path: "/login", element: <Login /> },
        { path: "/privacy", element: <Privacy /> },
        { path: "/forgot-password", element: <ForgotPassword /> },
        { path: "/reset-password", element: <ResetPassword /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const Landing = Loadable(lazy(() => import("../pages/Landing/index")));
const LandingStatic = Loadable(
  lazy(() => import("../pages/Landing/LandingStatic"))
);

const Register = Loadable(
  lazy(() => import("../pages/authentication/Register"))
);

// Dashboard
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/account/UserAccount"))
);

const AdminAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAdminAccount"))
);

// CLients
const PanelClient = Loadable(
  lazy(() => import("../pages/dashboard/clients/PanelClient"))
);

const PanelQuestions = Loadable(
  lazy(()=> import('../pages/dashboard/clients/PanelQuestions') )
)

//Panel Events
const PanelEvent = Loadable(
  lazy(() => import("../pages/dashboard/events/PanelEvent"))
);
const PanelEventByStandOrConference = Loadable(
  lazy(() => import("../pages/dashboard/PanelEventByStandOrConference"))
);

const Update = Loadable(
  lazy(() => import("../pages/dashboard/conferences/UpdateConference"))
);

// Main
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const ClientInactive = Loadable(
  lazy(() => import("../pages/PageInactiveClient"))
);
const Privacy = Loadable(lazy(() => import("../pages/Terms/Privacy")));
const ForgotPassword = Loadable(
  lazy(() => import("../pages/password/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/password/ResetPassword"))
);
