import { createSlice } from "@reduxjs/toolkit";
import { MessageStateType, MessageType } from "@types";
import { deleteMessageById, disabledMessageById, getMessageByEvent } from "services";

const initialState: MessageStateType = { 
  messages: [],
  loading: false
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
      setListMessage:(state, action) => ({
        ...state,
        messages: action.payload?.reverse()
      }),
      setLoadin: (state, action) => ({
        ...state,
        loading: action.payload
      }),
      deleteMessage: (state, action) => {
        
        return {
          ...state,
          messages: state.messages.filter(ms => ms.id !== action.payload)
        }
      },
      disabledMessage: (state, action) => ({
        ...state,
        messages: state.messages.map((ms) => {
          if(ms.id === action.payload.id){
            return action.payload
          }else {
            return ms
          }
        })
      })
  }
});

export const { 
  setListMessage,
  setLoadin,
  deleteMessage,
  disabledMessage
} = messageSlice.actions;

export default messageSlice.reducer;

export const fetchGetMessageByEvent = (id:number)=> async(dispatch:any) => {
  try {
    dispatch(setLoadin(true));
    const {data} = await getMessageByEvent(id);
    dispatch(setListMessage(data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setLoadin(false));
  }
}

export const fetchDeleteMessage = (messageId:number) => async (dispatch:any) => {
  try {
    await deleteMessageById(messageId);
    dispatch(deleteMessage(messageId));
  } catch (error) {
    console.error(error);
  }
}

export const fetchDisabledMessage = (message:MessageType) => async (dispatch:any) => {
  try {
    let form = {...message, status: (message.status === 1 ? 0:1)}
    await disabledMessageById(message.id, {status: form.status});
    dispatch(disabledMessage(form));
  } catch (error) {
    console.error(error);
  }
}