import axiosInstance from "utils/axios";

export const getTagsForEvent = () => axiosInstance.get('/tags?filter={"where":{"eventId":null}}');

export const getTagsForConference = (eventId:number) => {
    return axiosInstance.get(`events/${eventId}/tags`);
  };

export const getTagsConference = (id:number) => axiosInstance.get('/tag-conferences/'+id+"/tag")

export const getTagsForConferenceFilter = (eventId:number) => {
  return axiosInstance.get(`events/${eventId}/tags?filter={"where":{"status":1}}`);
};