import { ReactNode, useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { esES as dataGridEsES } from '@mui/x-data-grid';
import { esES as coreEsES } from '@mui/material/locale';
import { esES } from '@mui/x-date-pickers/locales';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import shadows from './shadows';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';

// declare module '@mui/styles/defaultTheme' {
//   interface DefaultTheme extends Theme {}
// }

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any
  }
}

type ThemeConfigProps = {
  children: ReactNode;
};

export default function ThemeConfig({ children }: ThemeConfigProps) {

  const themeOption:any = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      breakpoints,
      shadows: shadows(),
      customShadows: customShadows(),
      esES, // x-date-pickers translations
      dataGridEsES, // x-data-grid translations
      coreEsES, // core translations
    }),
    []
  )

  const theme = createTheme(themeOption);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
