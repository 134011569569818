import { createSlice } from "@reduxjs/toolkit";
import { PageType, EventSchema } from "@types";
import { getEventById, getPageEvent } from "services";
import { getAllExhibitors } from "services/Exhibitor";
import { getAllSpeakers } from "services/speakers";

const initialState: EventSchema = {
  isEmpty: true,
  events: [],
  event: null,
  exhibitors: [],
  assistants: [],
  speakers: [],
  currentTab:'eventos',
  flag:false,
  eventPage: undefined,
  // currentEvent : []
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload.events;
      state.isEmpty = action.payload.isEmpty;
    },
    currentEvent: (state, action) => {
      // state.currentEvent = action.payload.currentEvent;
    },
    setEvent: (state, action) => ({
      ...state,
      event: action.payload,
    }),
    setSpeaker: (state, action) => ({
      ...state,
      speakers: action.payload,
    }),
    setExhibitor: (state, action) => ({
      ...state,
      exhibitors: action.payload,
    }),
    setAssistants: (state, action) => ({
      ...state,
      assistants: action.payload,
    }),
    setcurrentTab:(state, action)=>({
      ...state,
      currentTab: action.payload
    }),
    setFlag:(state, action)=>({
      ...state,
      flag: action.payload
    }),
    setEventPage: (state, action) => ({
      ...state,
      eventPage: action.payload
    })
  },
});

export const {
  setEvents,
  currentEvent,
  setEvent,
  setSpeaker,
  setAssistants,
  setExhibitor,
  setcurrentTab,
  setFlag,
  setEventPage
} = eventSlice.actions;
export default eventSlice.reducer;

export const fetchGetEvent = (eventId: number) => async (dispatch: any) => {
  try {
    const filter = {
      include: [
        {
          relation: "assistantEvents",
          scope: {
            include: [
              {
                relation: "assistant",
              },
            ],
          },
        },
      ],
    };
    const { data } = await getEventById(eventId, filter);
    dispatch(setEvent(data));
  } catch (error) {
    console.error(error);
  }
};

export const fetchGetSpeaker = (eventId:number) => async (dispatch: any) => {
  try {
    const { data } = await getAllSpeakers(eventId);
    dispatch(setSpeaker(data));
  } catch (error) {
    console.error(error);
  }
};



export const fetchGetExhibitor = (eventId:number) => async (dispatch:any) => {
    try {
        const { data } = await getAllExhibitors(eventId);
        dispatch(setExhibitor(data));
    } catch (error) {
        console.log(error)
    }
}

export const fetchGetPageOfEvent = (eventId:number) => async (dispatch:any) => {
  try {
    const { data } = await getPageEvent(eventId);
    dispatch(setEventPage(data));
  } catch (error) {
    console.log(error)
  }
}
