import { createSlice } from '@reduxjs/toolkit';
import { Usuario } from '../../@types/user';

// type UserState = {
//   isLoading: boolean;
//   error: boolean;
//   userAuth: UserSchema | null,
//   notifications: NotificationSettings | null;
//   cards: CreditCard[] | null;
//   myProfile: null | Profile;
//   posts: UserPost[];
//   addressBook: UserAddressBook[];
//   invoices: UserInvoice[];
//   followers: Follower[];
//   friends: Friend[];
//   gallery: Gallery[];
// }

const initialState : Usuario = {
  id: 0,
  status: 0,
  email: "",
  isLoading: false,
  user : []
}
// const initialState: UserState = {
//   isLoading: false,
//   error: false,
//   userAuth: null,
//   notifications: null,
//   cards: [],
//   posts: [],
//   followers: [],
//   friends: [],
//   gallery: [],
//   myProfile: {
//     id: "1",
//     cover: "https://upload.wikimedia.org/wikipedia/commons/b/bb/C%C3%A1rtel_de_Jalisco_Nueva_Generaci%C3%B3n_logo_3.png",
//     position: 'UI Designer',
//     follower: 3,
//     following: 4,
//     quote:
//       'Tart I love sugar plum I love oat cake. Sweet roll caramels I love jujubes. Topping cake wafer..',
//     country: "Mexico we",
//     email: "tiomencho@cjng.com",
//     company: "Kenmeina",
//     school: "UPChiapas",
//     role: 'EL sicario',
//     facebookLink: `https://www.facebook.com/caitlyn.kerluke`,
//     instagramLink: `https://www.instagram.com/caitlyn.kerluke`,
//     linkedinLink: `https://www.linkedin.com/in/caitlyn.kerluke`,
//     twitterLink: `https://www.twitter.com/caitlyn.kerluke`
//   },
//   addressBook: [],
//   invoices: [],
// }

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setRootUser(state,action){
      state.isLoading = true;
      state.user = action.payload.user;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      // state.error = action.payload;
    },
    getUserSuccess(state, action) {
      state.isLoading = false;
      // state.userAuth = action.payload;
    },
  }
});

export default slice.reducer;
export const { 
  setRootUser,
  getUserSuccess,
  hasError,
  startLoading
} = slice.actions;