import { createContext, ReactNode, useReducer, useEffect } from "react";
import {
  ActionMap,
  AuthState,
  AuthUser,
  JWTContextType,
} from "../@types/authentication";
import { isValidToken, setSession } from "../utils/jwt";
import { getCurrentEnterpriseStatus, loginPost } from "../services";
import { dispatch as mainDispatch } from "../redux/store";
import { setRootUser } from "../redux/slices/user";
import axiosInstance from "../utils/axios";
import { GetUserToken } from "@types";

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
}

enum RolPrefix {
  Admin = "ADM",
  Owner = "OWN",
  Exhibitor = "EXH",
  Assistant = "ASS",
  Speaker = "SPK",
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    isAdmin: boolean;
  };
  [Types.Login]: {
    user: AuthUser;
    isAdmin: boolean;
    isAuthenticated: boolean;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTAction =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTAction) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isAdmin: true,
        user: action.payload.user,
      };

    case "LOGIN":
      return {
        ...state,
        isAdmin: true,
        user: action.payload.user,
      };

    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        user: null,
      };

    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: false,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  // const location = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    // TODO:  VALIDACIÓN AL INICIO PARA OBTENER DATOS DEL USUARIO

    const initialize = async () => {
      // console.log("initialize: ",root);
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const roleUser = window.localStorage.getItem("prefixType");
        const { data: UserAuth } = await axiosInstance.get<GetUserToken>(
          "/users/validateToken/" + accessToken
        );

        if (
          accessToken &&
          isValidToken(accessToken) &&
          (roleUser === RolPrefix.Admin || roleUser == RolPrefix.Owner)
        ) {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              isAdmin: true,
              user: UserAuth,
            },
          });
          mainDispatch(
            setRootUser({
              isLoading: false,
              user: UserAuth,
            })
          );
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              isAdmin: false,
              user: null,
            },
          });
        }
      } catch (error) {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            isAdmin: false,
          },
        });
      }
    };

    initialize()

  }, []);

  const login = async (email: string, passwordUser: string) => {
    const { data } = await loginPost(email, passwordUser);
    const { token, user, rolesUser } = data;
    const { enterpriseId } = rolesUser[0];
    let statusCheck = false;

    if (enterpriseId !== undefined) {
      const statusEnterprise = await getCurrentEnterpriseStatus(enterpriseId);
      if (statusEnterprise.data.status === 1) {
        statusCheck = true;
      } else {
        statusCheck = false;
      }
    }

    const isAdmin = rolesUser.some(
      (rol: { prefix: string }) =>
        rol.prefix === RolPrefix.Admin || rol.prefix === RolPrefix.Owner
    );

    if (isAdmin) {
      dispatch({
        type: Types.Login,
        payload: {
          user: { user, rolesUser },
          isAdmin: true,
          isAuthenticated: true,
        },
      });
      setSession(token, rolesUser[0].prefix, enterpriseId, "" + statusCheck);
    } else {
      dispatch({
        type: Types.Login,
        payload: {
          user: null,
          isAdmin: false,
          isAuthenticated: false,
        },
      });
    }
    dispatch({
      type: Types.Login,
      payload: {
        user: null,
        isAdmin: false,
        // isAdmin: true,
        isAuthenticated: false,
      },
    });
  };

  const logout = async () => {
    setSession(null, "", "", "");
    dispatch({ type: Types.Logout });
  };

  const register = async () => {};

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
