import jwtDecode from 'jwt-decode';
import axiosInstance from './axios';
// import axios from './axios';

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null , rolePrefix : string, currentEnterpriseId: string, clstatus: string ) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('prefixType', rolePrefix);
    localStorage.setItem('idntyEntp', currentEnterpriseId);
    localStorage.setItem('clStatus', clstatus);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('prefixType');
    localStorage.removeItem('idntyEntp');
    localStorage.removeItem('clStatus');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export {
  isValidToken,
  setSession
};