import { Questions } from "components/_client/Questions/helper";
import axiosInstance from "utils/axios";

export const createQuestions = (questions:Questions)=>{
  return axiosInstance.post('/questions',questions)
}

export const getQuestions = () =>{
    return axiosInstance.get('/questions')
}

export const deleteQuestions = (id:number) =>{
    return axiosInstance.delete(`/questions/${id}`)
}

export const updateQuestions = (id: number,questions:Questions) =>{
    return axiosInstance.patch(`/questions/${id}`,questions)
}