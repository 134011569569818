import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { eventReducer, userReducer, standReducer, messageReducer, notificationReducer} from './slices';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const userAuthPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['userAuth'],
}

const eventPersistConfig = {
  key: 'event',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['event'],
}

const standPersistConfig = {
  key: 'stand',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['stand'],
}

const rootReducer = combineReducers({
  user: persistReducer(userAuthPersistConfig, userReducer),
  event: persistReducer(eventPersistConfig, eventReducer ),
  stand: persistReducer(standPersistConfig, standReducer),
  message: messageReducer,
  notification:notificationReducer
})

export { rootPersistConfig, rootReducer };