import { createSlice } from "@reduxjs/toolkit";
import { getNotificationsByEvent } from "services/notifications.services";

const initialState = {
 notification : [],
 loading: false
}

export const notificationSlice = createSlice({
    name:'notification',
    initialState,
    reducers:{
        setListNotification:(state, action) => ({
            ...state,
            notification: action.payload
          }),
          setLoading: (state, action) => ({
            ...state,
            loading: action.payload
          }),
    }
})

export const {setListNotification,setLoading} = notificationSlice.actions;

export default notificationSlice.reducer;

export const fetchGetNotificationByEvent = (id:number)=> async (dispatch:any) => {
 try {
    dispatch(setLoading(true));
    const {data}=  await getNotificationsByEvent(id);
    dispatch(setListNotification(data));

 } catch (error) {
 }finally{
    dispatch(setLoading(false));
 }
}