import axiosInstance from "utils/axios";

export const createSponsor = (sponsors:any) => {
  return axiosInstance.post("/sponsors",sponsors);
};

export const getSponsor = (conference_id:number| undefined) =>{
  const query = { where: { conference_id: conference_id } };

    return axiosInstance.get(`/sponsors?filter=${encodeURI(JSON.stringify(query))}`)
}

export const updateSponsor = (sponsor:any,id:number)=>{
    return axiosInstance.patch(`/sponsors/${id}`,sponsor)
}

export const deleteSponsor = (id:number)=>{
  return axiosInstance.delete(`/sponsors/${id}`)
}

export const uploadLogoSponsor =(id:number,file:FormData)=>{
  return axiosInstance.post(`/sponsor/${id}/uploadLogo`,file)
}