// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
import { useEffect, useState } from 'react';
import { getEnterprise } from 'services';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();
  const urlImage = `/static/mock-images/avatars/avatar_1.jpg`;
  const urlImageSource = `${process.env.REACT_APP_BACKEND_API}/publicities/downloadPublicity/`
  const enterpriseId = user?.rolesUser[0].enterpriseId;
  const [resource, setResource] = useState(0)

  useEffect(()=>{
    async function getAvatar () {
      if(enterpriseId === undefined) return;
      const {data} = await getEnterprise(enterpriseId)
      setResource(data[0]?.resourceId)
    }
    getAvatar()
  },[])

  return (
    <MAvatar
      src={resource !== 0 ? `${urlImageSource}${resource}?${new Date().getTime()}` : urlImage}
      // src={`${urlImageSource}${resource}`}
      alt={user?.displayName}
      color={`${urlImageSource}${resource}` ? 'default' : createAvatar(user?.user.email).color}
      {...other}
      sx={{objectFit:'fill'}}
    >
      {createAvatar(user?.user.email).name}
    </MAvatar>
  );
}
