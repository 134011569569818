import './App.css';
import ThemeConfig from './theme';
import NotistackProvider from './components/NotistackProvider';
import ScrollToTop from './components/ScrollToTop';
import useAuth from './hooks/useAuth';
import Router from './routes';
import LoadingScreen from './components/LoadingScreen';

function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <NotistackProvider>
      <ScrollToTop />
        {isInitialized ? <Router /> : <LoadingScreen />}
      </NotistackProvider> 
    </ThemeConfig>
  );
}

export default App;
