import { ConferenceSchedulePatch, ConferenceSchedulePost } from "@types";
import axiosInstance from "utils/axios";

export const getConferences = (eventId: number) => {
  const urlParams = `{"where":{"eventId":${eventId}},"include":["speaker"]}`;
  return axiosInstance.get("conferences?filter=" + encodeURI(urlParams));
};

export const createConference = (conference: any) => {
  return axiosInstance.post("conferences", conference);
};

export const getConferenceById = (conferenceId: number) => {
  const initialParams = {
    include: ["speaker", "conferenceSchedules", "tagConferences","address","conferenceBanners"],
  };
  const additionalParams = {
    include: [
      {
        relation: "tagConferences",
        scope: {
          include: [
            {
              relation: "tag",
            }
          ],
        },
      }
    ],
  };

  // Combinar los dos objetos en uno solo
  const combinedParams = {
    include: [...initialParams.include, ...additionalParams.include],
  };
  const urlParams: string = JSON.stringify(combinedParams);

  return axiosInstance.get(
    `conferences/${conferenceId}?filter=` + encodeURI(urlParams)
  );
};

export const updateConferenceById = (conference: any, id: number) => {
  return axiosInstance.patch(`conferences/${id}`, conference);
};

export const createScheduleConference = (
  conferenceSchedule: ConferenceSchedulePost
) => {
  return axiosInstance.post(`conference-schedules`, conferenceSchedule);
};

export const getSchedulesByConfereceId = (conferenceId: number) => {
  return axiosInstance.get(`/conferences/${conferenceId}/conference-schedules`);
};

export const updateScheduleConference = (
  id: number,
  conferenceSchedule: ConferenceSchedulePatch
) => {
  return axiosInstance.patch(`conference-schedules/${id}`, conferenceSchedule);
};

export const deleteConferenceScheduleById = (id: number) => {
  return axiosInstance.delete(`/conference-schedules/${id}`);
};

export const updateConferenceBanner = (conferenceId: number, file: File) => {
  const formData = new FormData();
  formData.append("archivo", file);
  return axiosInstance.post(
    `conferences/updateBanner/${conferenceId}`,
    formData
  );
};

export const uploadConferenceBanner = (conferenceId: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return axiosInstance.post(
    `/conferences/${conferenceId}/conference-banners`,
    formData
  );
};

export const uploadProfileSpeakerService = (speakerId: number, file: File) => {
  const formData = new FormData();
  formData.append("archivo", file);
  return axiosInstance.post(`speakers/uploadProfile/${speakerId}`, formData);
};

export const updateProfileSpeaker = (speakerId: number, file: File) => {
  const formData = new FormData();
  formData.append("archivo", file);
  return axiosInstance.post(`speakers/updateProfile/${speakerId}`, formData);
};

export const createEventConference = (conferenceId: any, conference: any) => {
  return axiosInstance.post(`conferences/${conferenceId}/events`, conference);
};

export const getConferenceConference = (conferenceId: string) => {
  return axiosInstance.get(`conferences/${conferenceId}/events`);
};

export const pruebass = () => {
  return axiosInstance.get("/publicities/1/resource");
};

export const UploadConferenceBannerMultiple = (id: number | undefined, formData: FormData) => {
  return axiosInstance.post(`/conferences/${id}/conference-banners`, formData);
};

export const getConferenceBanners = (id:number) =>{
  return axiosInstance.get(`/conferences/${id}/conference-banners`)
}

export const deleteBanners = (id:number | undefined, idResource:number) =>{
  return axiosInstance.delete(`/conference-banners/${id}/${idResource}`)
}

export const countAssistant = (conferenceId:number) =>{
  return axiosInstance.get(`/conferences/${conferenceId}/assistant/count`)
}

export const patchAdresses = (id:number, addresses:any) =>{
  return axiosInstance.patch(`/addresses/${id}`,addresses)
}