import axiosInstance from "utils/axios";

export const getEnterprises = (filter?:any) => {
  return axiosInstance.get(`/enterprises?filter=${encodeURI(JSON.stringify(filter ?? {}))}`);
};

export const getEnterproseById = (id:number, filter?:any) => {
  return axiosInstance.get(`/enterprises/${id}?filter=${encodeURI(JSON.stringify(filter??{}))}`);
}

export const newEnterprise = (values: Object) => {
  return axiosInstance.post("/enterprises", values);
};

export const updateEnterprise = (enterprise: Object, eId: number) => {
  return axiosInstance.patch(`/enterprises/${eId}`, enterprise);
};

export const getEnterprise = (id: number) => {
  return axiosInstance.get(
    `/enterprises?${encodeURI(`filter={"where":{"id":${id}}}`)}`
  );
};

export const deleteEnterprise = (id: number) => {
  return axiosInstance.delete(`/enterprises/${id}`);
};

export const disableEnterprise = (id: number, status: number) => {
  return axiosInstance.patch(`/enterprises/changeStatus/${id}`, {
    status: status,
  });
};

export const getCurrentEnterpriseStatus = (id: number) => {
  return axiosInstance.get(
    `/enterprises/${id}?${encodeURI(`filter={"fields":{"status":true}}`)}`
  );
};

export const uploadLogoResource = (enterpriseId: number, logo: FormData) => {
  return axiosInstance.post(`/enterprises/uploadlogo/` + enterpriseId, logo, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateLogoResource = (enterpriseId: number, logo: FormData) => {
  return axiosInstance.post(`/enterprises/updateLogo/` + enterpriseId, logo, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getAssistants = (entepriseId: number) => {
  return axiosInstance.get(
    `/enterprises/${entepriseId}/assistants`
  );
};

export const changeStatusAssistant = (assistantId: number, statusUser: number) => {
  return axiosInstance.patch(`/users/changeStatus/${assistantId}`, {
    status: statusUser,
  } );
};
