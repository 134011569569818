import axiosInstance from "utils/axios";

export const getAllExhibitors = (eventId: number) => {
  return axiosInstance.get(`/users/user-exhibitor-by-event/${eventId}`);
};

export const createExhibitors = (values: any) => {
  return axiosInstance.post(`exhibitors`, values);
};

export const updateExhibitor = (values: any, id: number | undefined) => {
  return axiosInstance.patch(`exhibitors/${id}`, values);
};
