import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
import { styled } from "@mui/material/styles";
// material
import { Box, Link, Container, Typography } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainFooter from './MainFooter';
import MainNavbar from './MainNavbar';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = /^\/[^/]+\/inicio$/.test(pathname);

  // const Content = styled("div")(({ theme }) => ({
  //   [theme.breakpoints.up("md")]: {
  //     display: "flex",
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     display: "block",
  //     width: "100%",
  //     height: "100%",
  //   },
  // }));

  const BoxTypography = styled(Box)(({ theme }) => ({
    mt: 1,
    mb: 12,
  }));

  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'black',
            color: 'white'
          }}
        >
          <Container maxWidth="lg">
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} />
            </ScrollLink>

            <Typography variant="caption" component="p">
              © Epilog Digital Innovation.
              <br />Hecho por &nbsp;
              <Link href="">Epilog</Link>
            </Typography>
            <BoxTypography>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", mt: 1, mb: 4 }}
              >
                Los datos personales recabados serán protegidos, incorporados y
                tratados con fines estadísticos, promocionales y de mejora. La
                información obtenida es confidencial y es propiedad de Epilog Digital Innovation. Puedes consultar nuestro aviso privacidad en{" "}
                <a href="/privacy">https://develop.expos.epilog-di.com/privacy</a>
              </Typography>
            </BoxTypography>
          </Container>
        </Box>
      )}
    </>
  );
}
