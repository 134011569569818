
function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  landingPage: path(ROOTS_AUTH, '/'),
  resetPassword: '/forgot-password',
  verify: path(ROOTS_AUTH, '/verify')
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/privacy',
  page404: '/404',
  page500: '/500',
  landing: '/:prefix/inicio',
  landingStatic:'/',
  register: ':prefix/register',
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  clients:{
    root: path(ROOTS_DASHBOARD, '/client'),
    //children to client<
    detail: {
      root: path(ROOTS_DASHBOARD, '/client/enterprise'),
      expos: path(ROOTS_DASHBOARD, '/client/enterprise/events'), 
      questions:path(ROOTS_DASHBOARD, '/client/questions'),
    },
  },
  events: {
    root: path(ROOTS_DASHBOARD, '/events'),
    list: path(ROOTS_DASHBOARD, '/events/'),
    enable: path(ROOTS_DASHBOARD, '/panel/enable'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    admin: path(ROOTS_DASHBOARD,'/user/admin')
  }
};