import {  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

interface  DialogProductProps{
open:boolean;
handleClose:any;
update:boolean;
value:any;
setValue:any;
loading:boolean
saveProduct:any;
}

function DialogProduct({handleClose,loading,open,setValue,update,value,saveProduct}:DialogProductProps) {
  return (
    <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {update ? "Actualizar Producto" : "Crear Producto"}
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <br />
          <TextField
          sx={{mb:2}}
            InputProps={{
              inputProps: {
                autoCapitalize: "characters",
              },
            }}
            fullWidth
            label="Producto"
            name="name"
            value={value.name}
            onChange={(e) => {
              setValue({ ...value, name: e.target.value });
            }}
          />
          
          <TextField
            fullWidth
            label="Costo (Puntos)"
            name="cost"
            type="number"
            value={value.cost}
            onChange={(e) => {
              setValue({ ...value, cost: Number(e.target.value) });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!!loading} autoFocus onClick={() => handleClose()}>
            Cancelar
          </Button>
          <Button
            disabled={!(value.name.length && value.cost > 0) || !!loading}
            onClick={(e) => {
              saveProduct(e);
            }}
            autoFocus
          >
            {update ? "Actualizar" : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default DialogProduct
