import axiosInstance from "utils/axios";

export const getMessageByEvent= (
  eventId:number
) =>{
  return axiosInstance.get(`/messages/event/${eventId}`);
}

interface AddMessageForm {
  text: string,
  eventId: number,
  assistantsIds: Array<number>;
}
export const postAddMessage = (form:AddMessageForm) => {
  return axiosInstance.post('/messages/userMessage', form)
}

interface DisabledMessageForm {
  status: number
}
export const disabledMessageById = (messageId:number, form:DisabledMessageForm) => {
  return axiosInstance.patch(`/messages/${messageId}/disabled`,form);
}

export const deleteMessageById = (messageId:number) => {
  return axiosInstance.delete(`/messages/${messageId}/all`);
}